import React from 'react';
import Section from '../Section/Section';
import Box from '../../../components/Box/Box';
import Code from '../../../components/Code/Code';


const features = [
  'Only 10kB (4kB gzipped), including JavaScript, HTML, CSS and XML',
  'Beautiful themes, easy to customize by SASS (SCSS)',
  'Modular for minimal bundle size',
  'Easy to add new languages and extensions',
  'Displaying line numbers',
  'Highlighting lines',
  'Displaying a language name',
  'Displaying a code caption',
  'HTML generation for SSR',
  'Internet Explorer 10',
];

export default function About() {
  return (
    <Section heading="About" isNarrow>
      <p>
        RyuseiLight is a beautiful, lightweight and extensible Syntax Highlighter, written in TypeScript.
        It tokenizes code by regular expressions likewise other popular highlighters.
        One of the biggest problem of regex based highlighter, however, is how to handle nested syntax,
        such as a template literal in JavaScript.
        That usually requires a very complex regex that is hard to understand.
      </p>

      <p>
        RyuseiLight adopts a state system that can count &quot;open&quot; and &quot;close&quot; symbols,
        inspired by <a href="https://microsoft.github.io/monaco-editor/monarch.html" target="_blank" rel="noopener noreferrer">Monarch</a>.
        Thanks to the system, it can be simply and correctly tokenized:
      </p>

      <Code sourceId="about" style={ { margin: '1.5rem 0' } }/>

      <p>
        RyuseiLight does not aim for a strict tokenizer, but for good balance with simplicity and correctness.
        Currently, (only) 11 languages are supported, sorry!
      </p>

      <p>
        Here is a summary of features:
      </p>

      <Box>
        <ul>
          { features.map( ( feature, index ) => (
            <li key={ index }>
              { feature }
            </li>
          ) ) }
        </ul>
      </Box>

      <p>
        Ryusei（流星, 流=flow, 星=star） is my personal project code,
        that means a shooting star in Japanese. ⭐
      </p>
    </Section>
  );
}