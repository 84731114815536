import React from 'react';
import Caution from '../../../assets/images/svg/caution.svg';
import Chat from '../../../assets/images/svg/chat.svg';
import Arrow from '../../../assets/images/svg/arrow.svg';
import * as styles from './box.module.scss';


const ICONS = {
  caution: Caution,
  info   : Chat,
  arrow  : Arrow,
};

export default function Box( { type, children } ) {
  return (
    <div className={ styles.box }>
      <Icon type={ type } className={ styles.boxIcon }/>

      <div className={ styles.boxContent }>
        { children }
      </div>
    </div>
  );
}

function Icon( { type, className } ) {
  const icon = ICONS[ type ];

  if ( icon ) {
    const IconComponent = () => React.createElement( icon );
    return (
      <div className={ className }>
        <IconComponent/>
      </div>
    );
  }

  return null;
}