import React from 'react';
import LinkButton from '../../../components/LinkButton/LinkButton';
import Code from '../../../components/Code/Code';
import { EXAMPLE_TS_01 } from '../../../../constants/examples';
import * as styles from './header.module.scss';
import Logo from '../../../../assets/images/svg/logo-text.svg';
import HeaderText from '../../../../assets/images/svg/header-text.svg';
import { classNames } from '../../../../utils';


export default function Header() {
  return (
    <header className={ styles.header }>
      <div className={ styles.headerContent }>
        <h1 className={ styles.headerHeading }>
          <span className={ 'visually-hidden' }>RyuseiLight</span>
          <Logo/>
        </h1>

        <div className={ styles.headerIntro }>
          RyuseiLight is a beautiful, lightweight and extensible syntax highlighter.
        </div>

        <div className={ styles.headerActions }>
          <LinkButton to="/guides/getting-started/">Get Started</LinkButton>
        </div>

        <Code
          className={ styles.headerCode }
          sourceId={ 'main' }
        />

        <div role="presentation" aria-hidden="true">
          <div className={ classNames( styles.headerDecorationObject, styles.headerDecorationGrad1 ) }>
          </div>
          <div className={ classNames( styles.headerDecorationObject, styles.headerDecorationGrad2 ) }>
          </div>
        </div>
      </div>

      <div role="presentation" aria-hidden="true" className={ styles.headerText }>
        <HeaderText />
      </div>
    </header>
  );
}