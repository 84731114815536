import React from 'react';
import Section from '../Section/Section';
import * as styles from './examples.module.scss';
import Code from '../../../components/Code/Code';
import LinkButton from '../../../components/LinkButton/LinkButton';
import { Link } from 'gatsby';
import { classNames } from '../../../../utils';


export default function Examples() {
  return (
    <Section heading="Examples" className={ styles.examples } isNarrow>
      <section className={ styles.examplesSection }>
        <h3>Default</h3>
        <p>The default style of RyuseiLight. No extension is required.</p>
        <Code sourceId="default" />
      </section>

      <section className={ styles.examplesSection }>
        <h3>Line Numbers</h3>
        <p>The Gutter and <Link to={ '/extensions/line-numbers/' }>LineNumbers</Link> extensions are required.</p>
        <Code sourceId="line-numbers" />

        <div role="presentation" aria-hidden="true">
          <div className={ classNames( styles.examplesDecorationObject, styles.examplesDecorationGrad1 ) }>
          </div>
        </div>
      </section>

      <section className={ styles.examplesSection }>
        <h3>Active Lines</h3>
        <p>The <Link to={ '/extensions/active-lines/' }>ActiveLines</Link> extension is required.</p>
        <Code sourceId="active-lines" />
      </section>

      <section className={ styles.examplesSection }>
        <h3>Language Name</h3>
        <p>The Overlay and <Link to={ '/extensions/language-name/' }>LanguageName</Link> extensions are required.</p>
        <Code sourceId="language-name" />
      </section>

      <section className={ styles.examplesSection }>
        <h3>Caption</h3>
        <p>The <Link to={ '/extensions/caption/' }>Caption</Link> extension is required.</p>
        <Code sourceId="caption" />
      </section>

      <section className={ styles.examplesSection }>
        <h3>Copy Button</h3>
        <p>The <Link to={ '/extensions/copy/' }>Copy</Link> extension is required.</p>
        <Code sourceId="copy" className="has-default-copy" />
      </section>

      <section className={ styles.examplesSection }>
        <h3>Diff</h3>
        <p>The <Link to={ '/extensions/diff/' }>Diff</Link> extension is required.</p>
        <Code sourceId="diff" />
      </section>

      <footer className={ styles.examplesFooter }>
        <LinkButton to="/extensions/">Browse Extensions</LinkButton>
      </footer>
    </Section>
  );
}