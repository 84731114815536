export const EXAMPLE_TS_01 = `
/**
 * The component for highlighting lines.
 *
 * @since 0.0.1
 */
export function ActiveLines( { event, root, options }: Renderer ): void {
  const lines = ( root && parseData( root ) ) || options.activeLines;

  if ( isArray( lines ) ) {
    const activeLines = normalize( lines );

    event.on( 'gutter:row:open', ( html, classes, index ) => {
      if ( activeLines[ index ] ) {
        classes.push( activeLines[ index ] );
      }
    } );
  }
}
`.slice( 1, -1 );

export const EXAMPLE_TS_02 = `
export function ActiveLines( { event, root, options }: Renderer ): void {
  const lines = ( root && parseData( root ) ) || options.activeLines;

  if ( isArray( lines ) ) {
    const activeLines = normalize( lines );

    event.on( 'gutter:row:open', ( html, classes, index ) => {
      if ( activeLines[ index ] ) {
        classes.push( activeLines[ index ] );
      }
    } );

    event.on( 'line:open', ( html, classes, index ) => {
      if ( activeLines[ index ] ) {
        classes.push( activeLines[ index ] );
      }
    } );
  }
}
`.slice( 1, -1 );


export const EXAMPLE_TS_03 = `
export function ActiveLines( { event, root, options }: Renderer ): void {
  const lines = ( root && parseData( root ) ) || options.activeLines;

  if ( isArray( lines ) ) {
    const activeLines = normalize( lines );

-    event.on( 'gutter:row:open', ( html, classes, index ) => {
-      if ( activeLines[ index ] ) {
-        classes.push( activeLines[ index ] );
-      }
-    } );
+    event.on( 'line:open', ( html, classes, index ) => {
+      if ( activeLines[ index ] ) {
+        classes.push( activeLines[ index ] );
+      }
+    } );
  }
}
`.slice( 1, -1 );

export const EXAMPLE_JS_01 = `
// A nested template literal
\`container \${
  isMobile()
    ? 'is-mobile'
    : \`container--\${ page.isFront() ? 'front' : 'page' }\`
}\`;
`.slice( 1, -1 );

export const EXAMPLE_JSX_01 = `
class AlertButton extends React.Component {
  const { message, label } = this.props;

  render() {
    return (
      <button className="button" onClick={ () => alert( message ) }>
        { label }
      </button>
    );
  }
}
`.slice( 1, -1 );

export const EXAMPLE_SCSS_01 = `
.button {
  $root: &;

  font-family: Roboto, 'Avenir Next Pro', sans-serif;
  font-size: .9rem;

  &__inner {
    padding: 1rem;
  }

  &--primary {
    color: white;

    #{ $root }__inner {
      background: $main-color;
    }
  }
}
`.slice( 1, -1 );

export const EXAMPLE_JSON_01 = `
{
  "users": {
    "title": "Users",
    "list": [
      {
        "name": "John Doe",
        "id": "001",
        "age": 38,
        "entries": [ 1, 3, 4 ],
        "premium": true,
        "icon": null
      },
    ]
  }
}
`.slice( 1, -1 );

export const EXAMPLE_HTML_01 = `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <title>HTML</title>
</head>
<body>
  <button class="button button--primary" type="button" onclick="onClick()">
    Click Me!  
  </button>
  
  <script>
    function onClick() {
      alert( 'hello!' );
    }
  </script>
</body>
</html>
`.slice( 1, -1 );