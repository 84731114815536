import React from 'react';
import Section from '../Section/Section';
import * as styles from './features.module.scss';
import { Link } from 'gatsby';
import Comet from '../../../../assets/images/svg/comet.svg';
import Cassiopeia from '../../../../assets/images/svg/cassiopeia.svg';
import Orbit from '../../../../assets/images/svg/orbit.svg';
import Rocket from '../../../../assets/images/svg/rocket.svg';
import Arrow from '../../../../assets/images/svg/arrow.svg';


const icons = {
  comet     : Comet,
  cassiopeia: Cassiopeia,
  orbit     : Orbit,
  rocket    : Rocket,
};

export default function Features() {
  return (
    <Section className={ styles.features } heading="Features" isHiddenHeading>
      <div className={ styles.featuresBody }>
        <Feature icon="comet" heading="Lightweight" link="Get Started" to="/guides/getting-started/">
          Only 10kB (4.2kB gzipped), including JavaScript, HTML, CSS and XML.
          Even with all extensions, the size will be only 16kB.
        </Feature>

        <Feature icon="cassiopeia" heading="Beautiful" link="Browse Themes" to="/guides/themes/">
          Colors are carefully selected for harmonious appearance.
          Easy to create your own theme by SASS (SCSS).
        </Feature>

        <Feature icon="orbit" heading="Modular" link="Browse Extensions" to="/extensions/">
          All extensions and languages are modular.
          Build your small highlighter with really necessary modules.
        </Feature>

        <Feature icon="rocket" heading="Extensible" link="View Documents" to="/enhancement/defining-languages/">
          Languages can be defined or modified as you like.
          New functionality can be added by creating your own extension.
        </Feature>
      </div>
    </Section>
  );
}

function Feature( { icon, heading, children, to = '/', link } ) {
  if ( ! icons[ icon ] ) {
    throw new Error( `Icon:${ icon } was not found.` );
  }

  const Icon = () => React.createElement( icons[ icon ] );

  return (
    <section className={ styles.feature }>
      <header className={ styles.featureHeader }>
        <Icon/>
        <h3>{ heading }</h3>
      </header>
      <div>
        { children }
      </div>

      <Link to={ to } className={ styles.featureLink }>
        <Arrow/>
        <span>
          { link }
        </span>
      </Link>
    </section>
  );
}