import React from 'react';
import { classNames } from '../../../../utils';
import * as styles from './section.module.scss';
import Content from '../../../layout/Content/Content';


export default function Section( { className, heading, children, isNarrow, isWide, isHiddenHeading } ) {
  return (
    <section className={ classNames( styles.section, className ) }>
      <Content isNarrow={ isNarrow } isWide={ isWide }>
        <h2 className={ classNames( styles.sectionHeading, isHiddenHeading && 'visually-hidden' ) }>
          { heading }
        </h2>
        <div className={ styles.sectionContent }>
          { children }
        </div>
      </Content>
    </section>
  );
}