import React from 'react';
import Layout from '../components/layout/Layout/Layout';
import Features from '../components/scenes/Home/Features/Features';
import Examples from '../components/scenes/Home/Examples/Examples';
import Header from '../components/scenes/Home/Header/Header';
import About from '../components/scenes/Home/About/About';
import SEO from '../components/seo/SEO/SEO';


export default function Home() {
  return (
    <Layout>
      <SEO />

      <Header />
      <Features />
      <Examples />
      <About />
    </Layout>
  );
}