import React from 'react';
import '@ryusei/light/dist/css/themes/ryuseilight-ryusei.min.css';
import { RyuseiLight, languages, Extensions } from '@ryusei/light';
import { classNames } from '../../../utils';
import * as styles from './code.module.scss';
import './themes.scss';
import { graphql, useStaticQuery } from 'gatsby';


const AddClasses = ( { event, options } ) => {
  if ( options.containerClass ) {
    event.on( 'open', ( append, containerClasses ) => {
      containerClasses.push( options.containerClass );
    } );
  }

  if ( options.bodyClass ) {
    event.on( 'body:open', ( append, bodyClasses ) => {
      bodyClasses.push( options.bodyClass );
    } );
  }
};

RyuseiLight.register( Object.values( languages ).map( language => language() ) );
RyuseiLight.compose( { ...Extensions, AddClasses } );

export default function Code( {
  code,
  language,
  lineNumbers,
  activeLines,
  languageName,
  caption,
  copy,
  diff,
  style = {},
  className = '',
  bodyClass = '',
  theme,
  sourceId,
} ) {
  const ryuseilight = new RyuseiLight( {
    language,
    lineNumbers,
    activeLines,
    languageName,
    caption,
    copy,
    diff,
    bodyClass,
  } );

  if ( sourceId ) {
    return renderSource( sourceId );
  }

  return (
    <div
      style={ style }
      dangerouslySetInnerHTML={ { __html: ryuseilight.html( code, { language } ) } }
      className={ classNames( 'ryuseilight-wrapper', styles.code, className, theme ) }
    />
  );
}

function renderSource( sourceId, style, className, theme ) {
  const { allRyuseiLight: { edges } } = useStaticQuery( graphql`
    query {
      allRyuseiLight {
        edges {
          node {
            html
            id
          }
        }
      }
    }
  ` );

  const edge = edges.find( edge => edge.node.id === sourceId );

  if ( edge ) {
    return (
      <div
        style={ style }
        dangerouslySetInnerHTML={ { __html: edge.node.html } }
        className={ classNames( 'ryuseilight-wrapper', styles.code, className, theme ) }
      />
    );
  }

  return null;
}